import React from 'react'

import Layout from '../Components/Layout'
import SEO from '../Components/seo'
import { Link } from 'gatsby'

const headerImage = require('../images/hero.jpg')

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <div className="relative mx-auto">
            <img
                className="bg-transparent block rounded-lg w-full mx-auto object-cover object-center z-0"
                alt="header"
                style={{ height: '70vh' }}
                src={headerImage}
            />
            <div className="absolute inset-0 opacity-50 bg-black z-10" />
            <div className="absolute inset-0 flex flex-col z-30 justify-start">
                <h1
                    className="w-full sm:w-3/4 md:w-3/4 lg:w-2/4
                        text-2xl md:text-5xl font-bold text-white font-serif
                        pt-16 px-4 md:px-16"
                >
                    Welcome to the law offices of{' '}
                    <span className="whitespace-no-wrap">Trevin H. Preble</span>
                </h1>
                <h3
                    className="w-full md:w-3/4 lg:2/4
                        text-lg md:text-3xl font-bold text-white font-serif
                        pt-8 px-4 md:px-16"
                >
                    Working tirelessly every day for the needs of our clients
                </h3>
                <Link
                    className="bg-white hover:bg-gray-200
                    text-black font-bold py-2 px-8
                    self-start mx-4 md:mx-16 mt-8 rounded-full"
                    to="/contact"
                >
                    Get in touch
                </Link>
            </div>
        </div>
        <div
            className="container mx-auto pb-16 px-4 mt-24"
            style={{ maxWidth: '1080px' }}
        >
            <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-3/4 sm:pr-12">
                    <h1 className="mb-4 text-3xl font-serif border-b pb-4">
                        Criminal, Family, and Bankruptcy Attorney in Lincoln, NE
                    </h1>
                    <p className="text-justify">
                        Preble Law Firm, PC, LLO, focuses solely on providing
                        our clients with the best possible representation.
                    </p>
                    <p className="text-justify mt-6">
                        Finding the right attorney can be a daunting task and
                        that's where Central Nebraska Attorneys stand out among
                        the rest. Trevin H. Preble has been litigating and
                        winning cases for years. Whether it's a DUI, custody
                        fight, personal injury, bankruptcy, or general civil
                        litigation, the law office of Trevin H. Preble is here
                        to fight for you.
                    </p>
                    <p className="text-justify mt-6">
                        Call us to schedule a free consultation.
                    </p>
                    <button
                        className="hover:text-gray-100
                    font-bold py-2 px-8
                    mt-8 rounded-full
                    text-white bg-gold hover:bg-gold-darker
                    "
                    >
                        More about our firm
                    </button>
                </div>
                <div className="w-full md:w-1/4 mt-8 md:mt-0">
                    <h1 className="mb-4 text-3xl font-serif border-b pb-4">
                        Contact
                    </h1>
                    <p className="font-bold pb-2">Location</p>
                    <div className="flex">
                        <p className="">
                            1023 Lincoln Mall, Suite 101
                            <br />
                            Lincoln NE, 68508
                        </p>
                    </div>

                    <p className="font-bold mt-8 pb-2">Info</p>
                    <p className="">
                        <span className="w-1/6 inline-block text-sm font-semibold">
                            P:
                        </span>{' '}
                        (402) 467-4270
                        <br />
                        <span className="w-1/6 inline-block text-sm font-semibold">
                            F:
                        </span>{' '}
                        (402) 475-7989
                        <br />
                        <span className="w-1/6 inline-block text-sm font-semibold">
                            E:
                        </span>{' '}
                        tpreble@preblelawfirm.com
                    </p>
                </div>
            </div>
            <div className="mt-8 flex flex-row"></div>
        </div>
    </Layout>
)

export default IndexPage
